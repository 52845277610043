import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PubSub from "pubsub-js";
var setAnalyticsDataLayer = require("../../../lib/analytics-datalayer.js");
import { useAuth } from "../../../Auth";
export default function FunckyUI(props) {
    var funckyUri = useParams().funckyUri;
    var funckySrc = encodeURI(decodeURI(funckyUri !== null && funckyUri !== void 0 ? funckyUri : ''));
    // const funckySrc = encodeURI(decodeURI(`https://sbox-deliveryservices.experian.com/funcky-testpartnerphoenix/`))
    var auth = useAuth();
    useEffect(function () {
        var breadcrumbAnalytics = {
            primaryCategory: "Funcky UI result details",
            subCategory: "Funcky UI result detail"
        };
        setAnalyticsDataLayer.default('Funcky UI result details', auth.profile.uid, auth.profile.lanID);
        PubSub === null || PubSub === void 0 ? void 0 : PubSub.publish('analytics.PageLoad', 'window.digitalData');
    }, []);
    return (_jsx(_Fragment, { children: _jsx("iframe", { src: funckySrc, width: "99.5%", height: "99.2%", frameBorder: "0", style: { border: 0, padding: 0 } }) }));
}
